<template>
    <Button label="Ativar" icon="pi pi-check" @click="ativarDesativar('A')" v-if="evento?.status == 0" class="ml-2 mr-0" />
    <Button label="Desativar" icon="pi pi-times" class="p-button-danger ml-2 mr-0" @click="ativarDesativar('D')" v-if="evento?.status == 1" />
</template>

<script>
import EventosServices from './services';

export default {
    props: {
        evento: {
            type: Object,
        },
    },

    emits: ['atualizar'],

    methods: {
        ativarDesativar(acao) {
            this.$store.dispatch('addRequest');
            let promisse;
            if (acao == 'A') {
                promisse = EventosServices.ativar(this.evento.eventoId);
            } else {
                promisse = EventosServices.desativar(this.evento.eventoId);
            }
            promisse.then((response) => {
                if (response?.success) {
                    this.$store.dispatch('setAtualizar', true);
                    this.$emit('atualizar', response.data);
                    this.$toast.add({ severity: 'success', summary: 'Evento', detail: 'Evento atualizado com sucesso', life: 3000 });
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Evento', detail: 'Erro ao atualizar Evento', life: 3000 });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
