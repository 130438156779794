<template>
    <font-awesome-icon
        icon="download"
        @click="download()"
        size="lg"
        class="icon-button ml-2 p-text-primary"
        title="Download"
        v-if="$temAcessoView('TEMPL-02')"
    />
</template>

<script>
import Services from './services';

export default {
    props: {
        evento: {
            type: Object,
        },
    },

    methods: {
        download() {
            this.$store.dispatch('addRequest');
            Services.downloadImagemCredenciamento(this.evento.eventoId)
                .then((response) => {
                    if (response?.success) {
                        this.$download(response.data, this.evento.nomeImagemCredenciamento);
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Eventos',
                            detail: 'Erro ao realizar download',
                            life: 3000,
                        });
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
    },
};
</script>
