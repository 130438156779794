<template>
    <painel :titulo="titulo" icone="pi pi-calendar" :refreshFunction="obterEvento" v-show="mostrarEvento">
        <div class="mb-2">
            <TabView v-model:activeIndex="activeIndex">
                <TabPanel header="Dados Gerais">
                    <div class="mb-4" v-if="evento">
                        <Message :closable="false" severity="warn" v-if="evento.nivel == 0 && !evento.configurado && !evento.participantesInternos">
                            <strong>Evento pendente de configurações adicionais</strong>
                        </Message>
                        <detalhe :size="cabecalho" titulo="Evento Pai" v-if="evento.eventoPai"
                            >{{ evento.eventoPai.codigo }} - {{ evento.eventoPai.nome }}</detalhe
                        >
                        <detalhe :size="cabecalho" titulo="Código">{{ evento.codigo }}</detalhe>
                        <detalhe :size="cabecalho" titulo="Nome">{{ evento.nome }}</detalhe>
                        <detalhe :size="cabecalho" titulo="Descrição">{{ evento.descricao }}</detalhe>
                        <detalhe :size="cabecalho" titulo="Código Interno">{{ evento.codigoInterno }}</detalhe>
                        <detalhe :size="cabecalho" titulo="Data Inicio">{{ $dateFormat(evento.dataInicio, 'DD/MM/yyyy') }} </detalhe>
                        <detalhe :size="cabecalho" titulo="Data Fim">{{ $dateFormat(evento.dataFim, 'DD/MM/yyyy') }} </detalhe>
                        <detalhe :size="cabecalho" titulo="Responsável"
                            ><span v-if="evento.codResponsavel">({{ evento.codResponsavel }}) </span>{{ evento.responsavel }}</detalhe
                        >
                        <detalhe :size="cabecalho" titulo="Código Externo" v-if="evento.nivel == 0 && !evento.participantesInternos">{{
                            evento.codigoExterno
                        }}</detalhe>
                        <detalhe :size="cabecalho" titulo="Conta" v-if="evento.nivel == 0 && evento.contaEventoId">{{ evento.conta.nome }}</detalhe>
                        <detalhe :size="cabecalho" titulo="Sincronismo" v-if="evento?.nivel == 0 || evento?.participantesInternos">
                            <status :status="evento.statusDescricao"></status>
                        </detalhe>
                        <detalhe :size="cabecalho" titulo="Gera Atendimento">
                            <status :status="evento.geraAtendimento ? 'SIM' : 'NÃO'"></status>
                        </detalhe>
                        <detalhe :size="cabecalho" titulo="Sincronizar Checkins Externos" v-if="evento?.nivel == 0">
                            <status :status="evento.sincronizarCheckinExterno ? 'SIM' : 'NÃO'"></status>
                        </detalhe>
                        <detalhe :size="cabecalho" titulo="Buscar CNPJ" v-if="evento.nivel == 0 || (evento.nivel != 0 && !evento.eventoPai.buscarCnpj)">
                            <status :status="evento.buscarCnpj ? 'SIM' : 'NÃO'"></status>
                        </detalhe>
                        <detalhe :size="cabecalho" titulo="Gratuito" v-if="evento.codigoInterno != '0'">
                            <status :status="evento.eventoGratuito ? 'SIM' : 'NÃO'"></status>
                        </detalhe>
                        <detalhe :size="cabecalho" titulo="Obter Participantes do SAS">
                            <status :status="evento.participantesInternos ? 'SIM' : 'NÃO'"></status>
                        </detalhe>
                    </div>
                </TabPanel>
                <TabPanel header="Eventos" v-if="mostrarAbaEventos">
                    <eventos :evento="evento"></eventos>
                </TabPanel>
                <TabPanel header="Configurações do Evento" v-if="mostrarAbaEventos">
                    <detalhar-evento-configuracao :evento="evento" ref="eventoConfig" @obterEventoAtualizado="obterEvento()"></detalhar-evento-configuracao>
                </TabPanel>
                <TabPanel header="Configurações de Atendimento" v-if="mostrarAbaAtendimentoDetalhe">
                    <detalhar-atendimento-detalhe :evento="evento" :buttonAtendimento="buttonAtendimento"></detalhar-atendimento-detalhe>
                </TabPanel>
                <TabPanel header="Participantes">
                    <participantes :evento="evento" v-if="evento"></participantes>
                </TabPanel>
                <TabPanel header="Checkins">
                    <checkins :evento="evento" v-if="evento"> </checkins>
                </TabPanel>
                <TabPanel header="Atendimento" v-if="mostrarAbaAtendimentoDetalhe">
                    <Atendimento :evento="evento" v-if="evento"> </Atendimento>
                </TabPanel>
                <TabPanel header="Dashboard">
                    <dashboard :evento="evento" v-if="evento"></dashboard>
                </TabPanel>
                <TabPanel header="Credenciamento">
                    <credenciamento :evento="evento" v-if="evento" @atualizar="obterEvento()"></credenciamento>
                </TabPanel>
                <TabPanel header="Agenda">
                    <Agenda :evento="evento" v-if="evento"></Agenda>
                </TabPanel>
            </TabView>
        </div>
        <btn-voltar :route="{ name: 'Checkins_Eventos' }" v-if="evento?.nivel == 0"></btn-voltar>
        <btn-voltar :route="{ name: 'Checkins_Eventos_Detalhar', params: { id: this.evento.eventoPaiId } }" v-if="evento?.nivel > 0"></btn-voltar>
        <btn-ativar-desativar
            :evento="evento"
            @atualizar="evento = $event"
            v-if="evento?.nivel == 0 && activeIndex == 0 && evento?.status != 2"
        ></btn-ativar-desativar>
        <btn-atualizar v-if="activeIndex == 0 && evento?.status != 2" :evento="evento"></btn-atualizar>
        <btn-atualizar-atendimento-detalhe
            :evento="evento"
            v-if="evento?.geraAtendimento && ((evento?.nivel == 0 && activeIndex == 3) || (evento?.nivel != 0 && activeIndex == 1))"
        ></btn-atualizar-atendimento-detalhe>
        <btn-gerar-qr-code :evento="evento" v-if="activeIndex == 0"></btn-gerar-qr-code>
        <Button
            label="Atualizar"
            icon="pi pi-pencil"
            class="ml-2"
            @click="$refs.eventoConfig.atualizar()"
            v-if="evento?.nivel == 0 && activeIndex == 2"
            :disabled="!$refs.eventoConfig.formularioOk"
        />
        <Button label="Credenciamento" icon="pi pi-user" class="ml-2" @click="toCredenciamento()" v-if="activeIndex == 0" />
        <Button
            label="Trazer Conf Atendimento"
            class="ml-2"
            v-model="buttonAtendimento"
            @click="acionarButton()"
            v-if="evento?.geraAtendimento && evento?.eventoPai && ((evento?.nivel == 0 && activeIndex == 3) || (evento?.nivel != 0 && activeIndex == 1))"
        ></Button>
    </painel>
    <router-view :evento="evento"></router-view>
</template>

<script>
import BtnAtivarDesativar from './BtnAtivarDesativar';
import Eventos from './Eventos';
import Atendimento from './Atendimento.vue';
import Participantes from '../participantes/Participantes';
import Checkins from '../checkins/Checkins';
import Agenda from './Agenda.vue';
import BtnAtualizar from './BtnAtualizar';
import EventosServices from './services';
import DetalharAtendimentoDetalhe from './DetalharAtendimentoDetalhe';
import DetalharEventoConfiguracao from './DetalharEventoConfiguracao';
import BtnAtualizarAtendimentoDetalhe from './BtnAtualizarAtendimentoDetalhe';
import Dashboard from './Dashboard';
import BtnGerarQrCode from './BtnGerarQrCode.vue';
import Credenciamento from './Credenciamento.vue';

export default {
    components: {
        Agenda,
        Atendimento,
        Eventos,
        Participantes,
        BtnAtivarDesativar,
        BtnAtualizar,
        DetalharAtendimentoDetalhe,
        DetalharEventoConfiguracao,
        Checkins,
        BtnAtualizarAtendimentoDetalhe,
        Dashboard,
        BtnGerarQrCode,
        Credenciamento,
    },

    data() {
        return {
            evento: null,
            buttonAtendimento: false,
            cabecalho: '185',
            activeIndex: 0,
        };
    },

    methods: {
        obterEvento() {
            this.$store.dispatch('addRequest');
            EventosServices.obterPorId(this.$route.params.id)
                .then((response) => {
                    if (response?.success) {
                        this.evento = response.data;
                        this.processarView();
                    } else {
                        this.evento = null;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        acionarButton() {
            this.buttonAtendimento = true;
        },

        obterParticipantesEvento() {
            this.$store.dispatch('addRequest');
            EventosServices.obterParticipantesEvento(this.$route.params.id)
                .then((response) => {
                    if (response?.success) {
                        this.participantes = response.data;
                    } else {
                        this.participantes = null;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        obterEventosEvento() {
            this.$store.dispatch('addRequest');
            EventosServices.obterEventosEvento(this.$route.params.id)
                .then((response) => {
                    if (response?.success) {
                        this.eventosFilhos = response.data;
                    } else {
                        this.eventosFilhos = null;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        obterCheckinsEvento() {
            this.$store.dispatch('addRequest');
            EventosServices.obterCheckinsEvento(this.$route.params.id)
                .then((response) => {
                    if (response?.success) {
                        this.checkins = response.data;
                    } else {
                        this.checkins = null;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        voltar() {
            this.$router.push({ name: 'Checkins_Eventos' });
        },

        processarView() {
            if (this.$route.query.view == 'eventos') {
                this.activeIndex = 1;
            }
            if (this.$route.query.view == 'participantes') {
                if (this.evento.nivel == 0 && this.evento.geraAtendimento) {
                    this.activeIndex = 4;
                } else if ((this.evento.nivel == 0 && !this.evento.geraAtendimento) || (this.evento.nivel != 0 && this.evento.geraAtendimento)) {
                    this.activeIndex = 3;
                } else if (!this.evento.nivel == 0 && !this.evento.geraAtendimento) {
                    this.activeIndex = 2;
                }
            }
            if (this.$route.query.view == 'atendimento-detalhe') {
                if (this.evento.nivel == 0) {
                    this.activeIndex = 3;
                } else {
                    this.activeIndex = 1;
                }
            }
        },

        toCredenciamento() {
            let routeData = this.$router.resolve({
                name: 'Checkins_Credenciamento',
                query: {
                    evento: this.evento.eventoId,
                },
            });
            window.open(routeData.href, '_blank');
        },
    },

    computed: {
        titulo() {
            if (this.evento) {
                return `Evento - ${this.evento?.codigo} - ${this.evento?.nome}`;
            }
            return 'Evento';
        },

        mostrarEvento() {
            return this.$route.name == 'Checkins_Eventos_Detalhar';
        },

        mostrarAbaEventos() {
            if (this.evento) {
                return this.evento.nivel == 0;
            }
            return true;
        },

        mostrarAbaAtendimentoDetalhe() {
            if (this.evento) {
                return this.evento.geraAtendimento;
            }
            return true;
        },
    },

    mounted() {
        this.obterEvento();
    },

    watch: {
        '$route.params.id'() {
            if (this.$route.params.id) {
                this.obterEvento();
                this.activeIndex = 0;
            }
        },
    },
};
</script>
