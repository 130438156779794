<template>
    <tabela
        v-model:selection="selectedEvent"
        :data="eventos"
        :globalFilterFields="['nome', 'descricao', 'codigo', 'codigoExterno', 'codigoInterno', 'statusDescricao']"
        dataKey="eventoId"
        stateKey="dt-checkins-eventos"
        :salvarEstado="false"
        alinhamentoHeaderProp="justify-content-start"
    >
        <template #botoes>
            <Button
                class="ml-2"
                @click="gerarQrCodeConfiguracao()"
                label="Gerar QrCodes de Configuração"
                :badge="badgeContagem"
                icon="pi pi-qrcode"
                :disabled="!permiteGerarQrcode"
            ></Button>
            <div id="btnPosicaoDireita">
                <btn-inserir :nivel="nivel" :evento="evento" v-if="evento"></btn-inserir>
            </div>
        </template>
        <template #conteudo>
            <div style="clear: left">
                <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                <Column headerStyle="width: 7em">
                    <template #body="slotProps">
                        <btn-detalhar :evento="slotProps.data"></btn-detalhar>
                    </template>
                </Column>
                <Column field="nome" header="Nome" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.nome }}
                    </template>
                </Column>
                <Column field="codigoInterno" header="Código Interno" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.codigoInterno }}
                    </template>
                </Column>
                <Column field="statusDescricao" header="Sincronismo" :sortable="true" v-if="nivel == 0">
                    <template #body="slotProps">
                        <span class="cursor-pointer" :title="getStatusTitle(slotProps.data.statusDescricao)">
                            <div class="icon-text-contaniner">
                                <font-awesome-icon
                                    :icon="iconStatus(slotProps.data.statusDescricao)"
                                    size="lg"
                                    :class="iconClass(slotProps.data.statusDescricao)"
                                />
                            </div>
                        </span>
                    </template>
                </Column>
                <Column field="geraAtendimento" header="Gera Atendimento" :sortable="true">
                    <template #body="slotProps">
                        <span class="cursor-pointer" :title="getStatusTitle(slotProps.data.geraAtendimento)">
                            <div class="icon-text-contaniner">
                                <font-awesome-icon
                                    :icon="iconStatus(slotProps.data.geraAtendimento)"
                                    size="lg"
                                    :class="iconClass(slotProps.data.geraAtendimento)"
                                />
                            </div>
                        </span>
                    </template>
                </Column>
                <Column field="sincronizarCheckinExterno" header="Sincronizar Checkin Externo" :sortable="true">
                    <template #body="slotProps">
                        <span class="cursor-pointer" :title="getStatusTitle(slotProps.data.sincronizarCheckinExterno)">
                            <div class="icon-text-contaniner">
                                <font-awesome-icon
                                    :icon="iconStatus(slotProps.data.sincronizarCheckinExterno)"
                                    size="lg"
                                    :class="iconClass(slotProps.data.sincronizarCheckinExterno)"
                                />
                            </div>
                        </span>
                    </template>
                </Column>
                <Column field="buscarCnpj" header="Buscar CNPJ" :sortable="true" v-if="nivel == 0 || (nivel != 0 && !evento?.buscarCnpj)">
                    <template #body="slotProps">
                        <div class="icon-text-contaniner">
                            <font-awesome-icon :icon="iconStatus(slotProps.data.buscarCnpj)" size="lg" :class="iconClass(slotProps.data.buscarCnpj)" />
                        </div>
                    </template>
                </Column>
            </div>
        </template>
    </tabela>
</template>

<script>
import BtnDetalhar from './BtnDetalhar';
import BtnInserir from './BtnInserir';
import EventosServices from './services';
export default {
    data() {
        return {
            eventosData: null,
            selectedEvent: [],
            metaKey: true,
            listagemEventosQrCode: null,
            eventosSelecionados: [],
        };
    },
    props: {
        eventos: {
            type: Array,
        },

        nivel: {
            type: Number,
        },

        evento: {
            type: Object,
        },
    },

    methods: {
        iconStatus(status) {
            let icon;
            switch (status) {
                case 'ATIVO':
                case 'SIM':
                case true:
                    icon = 'check-circle';
                    break;
                case 'ERRO NA INTEGRAÇÃO':
                case 'INATIVO':
                case 'NÃO':
                case false:
                    icon = 'times-circle';
                    break;
                case 'EM PROCESSAMENTO':
                    icon = 'hourglass-half';
                    break;
            }
            return icon;
        },
        iconClass(status) {
            switch (status) {
                case 'SIM':
                case 'ATIVO':
                case true:
                    return 'icon-sucesso';
                case 'ERRO NA INTEGRAÇÃO':
                case 'NÃO':
                case 'INATIVO':
                case false:
                    return 'icon-erro';
                case 'EM PROCESSAMENTO':
                    return 'icon-aguardando';
            }
        },

        getStatusTitle(status) {
            let title;
            switch (status) {
                case 'ATIVO':
                case 'SIM':
                case true:
                    title = 'Ativo';
                    break;
                case 'ERRO NA INTEGRAÇÃO':
                case 'INATIVO':
                case 'NÃO':
                case false:
                    title = 'Inativo';
                    break;
                case 'EM PROCESSAMENTO':
                    title = 'Em Processamento';
                    break;
            }
            return title;
        },
        gerarQrCodeConfiguracao() {
            this.$store.dispatch('addRequest');
            EventosServices.gerarQrCodeConfiguracao(this.listagemEventosQrCode)
                .then((response) => {
                    if (response?.success) {
                        this.$download(response.data, `ListagemEventos.pdf`);
                        this.selectedEvent = [];
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Anexo',
                            detail: 'Erro ao emitir listagem dos eventos',
                            life: 3000,
                        });
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        obterEventoSelecionados(eventosSelecionados) {
            this.listagemEventosQrCode = {
                EventosId: eventosSelecionados.map((evento) => evento.eventoId),
            };
        },
        habilitar() {
            return true;
        },
    },

    components: {
        BtnDetalhar,
        BtnInserir,
    },

    computed: {
        permiteGerarQrcode() {
            return this.listagemEventosQrCode?.EventosId != null && this.listagemEventosQrCode.EventosId.length != 0;
        },

        badgeContagem() {
            if (this.selectedEvent.length === 0) {
                return '0';
            }
            return this.selectedEvent.length.toString();
        },
    },

    watch: {
        selectedEvent() {
            this.obterEventoSelecionados(this.selectedEvent);
        },
    },
};
</script>

<style scoped>
.containerFlex {
    float: left;
}
#btnPosicaoDireita {
    position: absolute;
    right: 0;
}
</style>