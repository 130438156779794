<template>
    <ProgressBar mode="indeterminate" style="height: 1em" v-if="carregando" />
    <TabView v-model:activeIndex="activeIndex" v-if="!carregando">
        <TabPanel header="Processados">
            <tabela
                :data="checkinsProcessados"
                :globalFilterFields="['codigoExterno', 'participante', 'protocolo']"
                dataKey="checkinId"
                stateKey="dt-checkins-processados"
            >
                <template #botoes>
                    <Button
                        label="Participantes (PDF)"
                        icon="pi pi-file-pdf"
                        @click="emitirRelatorioParticipantes('pdf', false)"
                        v-if="$temAcessoView('CHECKINS-EVENTOS-09') && checkinsProcessados?.length > 0"
                        class="ml-2"
                    />
                    <Button
                        label="Participantes Anonimizados (PDF)"
                        icon="pi pi-file-pdf"
                        @click="emitirRelatorioParticipantes('pdf', true)"
                        v-if="$temAcessoView('CHECKINS-EVENTOS-12') && checkinsProcessados?.length > 0"
                        class="ml-2"
                    />
                    <Button
                        label="Participantes (EXCEL)"
                        icon="pi pi-file-excel"
                        @click="emitirRelatorioParticipantes('xlsx', false)"
                        v-if="$temAcessoView('CHECKINS-EVENTOS-13') && checkinsProcessados?.length > 0"
                        class="ml-2"
                    />
                    <Button
                        label="Lista de Presença (PDF)"
                        icon="pi pi-file-pdf"
                        @click="emitirRelatorioListaPresenca()"
                        v-if="$temAcessoView('CHECKINS-EVENTOS-09') && checkinsProcessados?.length > 0"
                        class="ml-2"
                    />
                </template>
                <template #conteudo>
                    <Column field="codigoExterno" header="Código" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.codigoExterno }}</span>
                        </template>
                    </Column>
                    <Column field="participante" header="Participante" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.participante }}</span>
                        </template>
                    </Column>
                    <Column field="dataLeitura" header="Data Leitura" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ $dateFormat(slotProps.data.dataLeitura, 'DD/MM/YYYY HH:mm:ss') }}</span>
                        </template>
                    </Column>
                    <Column field="statusIntegracaoInternaDescr" header="Atendimento Solicitado" :sortable="true" v-if="evento.geraAtendimento">
                        <template #body="slotProps">
                            <span v-if="slotProps.data.statusIntegracaoInternaDescr == 'AGUARDANDO SOLICITAÇÃO DE INTEGRAÇÃO'">NÃO</span>
                            <span v-else>SIM</span>
                        </template>
                    </Column>
                    <Column field="dataCadastro" header="Recebido em" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ $dateFormat(slotProps.data.dataCadastro, 'DD/MM/YYYY HH:mm:ss') }}</span>
                        </template>
                    </Column>
                    <Column field="cpfResponsavel" header="Responsável" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.cpfResponsavel }}</span>
                        </template>
                    </Column>
                    <Column field="origem" header="Origem" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ obterDescricaoOrigem(slotProps.data.origem) }}</span>
                        </template>
                    </Column>
                </template>
            </tabela>
        </TabPanel>
        <TabPanel header="Não Processados">
            <tabela
                :data="checkinsNaoProcessados"
                :globalFilterFields="['codigoExterno', 'statusDescr', 'observacoes']"
                dataKey="checkinId"
                stateKey="dt-checkins-naoprocessados"
            >
                <template #conteudo>
                    <Column field="codigoExterno" header="Código" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.codigoExterno }}</span>
                        </template>
                    </Column>
                    <Column field="dataLeitura" header="Data Leitura" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ $dateFormat(slotProps.data.dataLeitura, 'DD/MM/YYYY HH:mm:ss') }}</span>
                        </template>
                    </Column>
                    <Column field="statusDescr" header="Status" :sortable="true">
                        <template #body="slotProps">
                            <status :status="slotProps.data.statusDescr"></status>
                        </template>
                    </Column>
                    <Column field="dataCadastro" header="Recebido em" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ $dateFormat(slotProps.data.dataCadastro, 'DD/MM/YYYY HH:mm:ss') }}</span>
                        </template>
                    </Column>
                    <Column field="observacoes" header="Observações" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.observacoes }}</span>
                        </template>
                    </Column>
                    <Column field="cpfResponsavel" header="Responsável" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.cpfResponsavel }}</span>
                        </template>
                    </Column>
                </template>
            </tabela>
        </TabPanel>
    </TabView>
</template>

<script>
import EventosServices from './services';
import OrigemEnum from './OrigemEnum';

export default {
    components: {},

    props: {
        evento: {
            type: Object,
        },
    },

    data() {
        return {
            carregando: false,
            checkinsProcessados: null,
            checkinsNaoProcessados: null,
            checkinsSelecionados: [],
            activeIndex: 0,
        };
    },

    methods: {
        obterCheckins() {
            this.carregando = true;
            EventosServices.obterCheckinsEvento(this.evento.eventoId)
                .then((response) => {
                    if (response?.success) {
                        this.checkinsProcessados = [...response.data.processados];
                        this.checkinsNaoProcessados = [...response.data.naoProcessados];
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Checkins',
                            detail: 'Erro ao obter checkins do evento',
                            life: 3000,
                        });
                    }
                })
                .finally(() => {
                    this.carregando = false;
                });
        },

        emitirRelatorioParticipantes(tipo, anonimizado) {
            this.$store.dispatch('addRequest');
            EventosServices.emitirRelatorioParticipantes(this.evento.eventoId, tipo, anonimizado)
                .then((response) => {
                    if (response?.success) {
                        this.$download(response.data, `RelacaoParticipantes_${this.evento.codigo}.${tipo}`);
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Anexo',
                            detail: 'Erro ao emitir relação dos participantes',
                            life: 3000,
                        });
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        emitirRelatorioListaPresenca() {
            this.$store.dispatch('addRequest');
            EventosServices.emitirRelatorioListaPresenca(this.evento.eventoId)
                .then((response) => {
                    if (response?.success) {
                        this.$download(response.data, `RelacaoPresencas_${this.evento.codigo}.pdf`);
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Anexo',
                            detail: 'Erro ao emitir lista de presença',
                            life: 3000,
                        });
                    }
                })
                .finally(() => this.$store.dispatch('removeRequest'));
        },
        obterDescricaoOrigem(id) {
            return OrigemEnum.ObterPorId(id).descricao;
        },
    },

    watch: {
        evento() {
            if (this.evento) {
                this.obterCheckins();
            }
        },
    },
    mounted() {
        if (this.evento) {
            this.obterCheckins();
        }
    },
};
</script>
