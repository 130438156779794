<template>
    <detalhe
        :size="cabecalho"
        v-for="eventoConfiguracao in eventoConfigIntegracao"
        :key="eventoConfiguracao.EventoConfigInteracaoId"
        :titulo="eventoConfiguracao.chave"
        :obrigatorio="true"
    >
        <InputText type="text" v-model="eventoConfiguracao.valor" style="width: 100%" maxlength="100" />
    </detalhe>
</template>

<script>
import EventosServices from './services';

export default {
    components: {},

    props: {
        evento: {
            type: Object,
        },
    },

    emits: ['obterEventoAtualizado'],

    data() {
        return {
            cabecalho: '140',
            activeIndex: 0,
            eventoConfigIntegracao: null,
            primeirasConfiguracoes: [
                {
                    chave: 'CNPJ',
                    valor: '',
                },
                {
                    chave: 'CPF',
                    valor: '',
                },
                {
                    chave: 'DATA_NASCIMENTO',
                    valor: '',
                },
                {
                    chave: 'GENERO',
                    valor: '',
                },
                {
                    chave: 'TELEFONE',
                    valor: '',
                },
            ],
        };
    },

    methods: {
        obterEventoConfigsIntegracao() {
            this.$store.dispatch('addRequest');
            EventosServices.obterConfigsIntegracaoEvento(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.eventoConfigIntegracao = response.data;
                    if (this.eventoConfigIntegracao.length == 0) {
                        this.eventoConfigIntegracao = [...this.primeirasConfiguracoes];
                    }
                } else {
                    this.eventoConfigIntegracao = [...this.primeirasConfiguracoes];
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            this.$store.dispatch('addRequest');
            let eventoConfiguracao = {
                configuracoes: [...this.eventoConfigIntegracao],
            };
            EventosServices.atualizarConfigsIntegracaoEvento(this.$route.params.id, eventoConfiguracao).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Configurações Evento',
                        detail: 'Configurações do evento atualizadas com sucesso',
                        life: 3000,
                    });
                    this.eventoConfigIntegracao = response.data;
                    this.$emit('obterEventoAtualizado');
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    computed: {
        formularioOk() {
            return this.eventoConfigIntegracao?.every((config) => {
                return config.valor != '' && config.valor != null;
            });
        },
    },

    watch: {
        evento() {
            if (this.evento) {
                this.obterEventoConfigsIntegracao();
            }
        },
    },

    mounted() {
        if (this.evento) {
            this.obterEventoConfigsIntegracao();
        }
    },
};
</script>
