<template>
    <ProgressBar mode="indeterminate" style="height: 1em" v-if="carregando" />
    <TabView v-model:activeIndex="activeIndex" v-if="!carregando">
        <TabPanel header="">
            <tabela
                :data="checkinsProcessados"
                :globalFilterFields="['codigoExterno', 'participante', 'protocolo']"
                dataKey="checkinId"
                stateKey="dt-checkins-processados"
            >
                <template #botoes>
                    <Button
                        label="Gerar Atendimentos"
                        icon="pi pi-plus"
                        @click="confirmarGerarAtendimentos()"
                        v-if="$temAcessoView('CHECKINS-EVENTOS-06') && evento?.geraAtendimento"
                        :disabled="checkinsSelecionados.length == 0"
                    />
                </template>

                <template #conteudo>
                    <Column headerStyle="width: 3em" v-if="evento?.geraAtendimento">
                        <template #header>
                            <font-awesome-icon icon="check-square" @click="toggleCheckins()" size="lg" class="icon-button" title="Marcar/Desmarcar Todos" />
                        </template>
                        <template #body="slotProps">
                            <div class="flex flex-nowrap">
                                <Checkbox
                                    class="ml-2"
                                    :value="slotProps.data"
                                    v-model="checkinsSelecionados"
                                    v-if="mostrarChkBox(slotProps.data.permiteIntegracao)"
                                />
                            </div>
                        </template>
                    </Column>
                    <Column headerStyle="width: 7em">
                        <template #body="slotProps">
                            <btn-detalhar :checkin="slotProps.data"></btn-detalhar>
                        </template>
                    </Column>
                    <Column field="codigoExterno" header="Código" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.codigoExterno }}</span>
                        </template>
                    </Column>
                    <Column field="participante" header="Participante" :sortable="true">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.participante }}</span>
                        </template>
                    </Column>
                    <Column field="statusIntegracaoInternaDescr" header="Status Atendimento" :sortable="true" v-if="evento.geraAtendimento">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.statusIntegracaoInternaDescr }}</span>
                        </template>
                    </Column>
                    <Column field="protocolo" header="Protocolo" :sortable="true" v-if="evento.geraAtendimento">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.protocolo }}</span>
                        </template>
                    </Column>
                    <Column field="dataSolicitacao" header="Data da Solicitação" :sortable="true">
                        <template #body="slotProps">
                            <span> {{ $dateFormat(slotProps.data.dataSolicitacao, 'DD/MM/YYYY HH:mm:ss') }}</span>
                        </template>
                    </Column>
                </template>
            </tabela>
        </TabPanel>
    </TabView>
</template>

<script>
import EventosServices from './services';
import CheckinEventosService from '../checkins/services';
import BtnDetalhar from '../checkins/BtnDetalhar.vue';

export default {
    components: {
        BtnDetalhar,
    },

    props: {
        evento: {
            type: Object,
        },
    },

    data() {
        return {
            carregando: false,
            checkinsProcessados: null,
            checkinsNaoProcessados: null,
            checkinsSelecionados: [],
            activeIndex: 0,
        };
    },

    methods: {
        obterCheckins() {
            this.carregando = true;
            EventosServices.obterCheckinsEvento(this.evento.eventoId)
                .then((response) => {
                    if (response?.success) {
                        this.checkinsProcessados = [...response.data.processados];
                        this.checkinsNaoProcessados = [...response.data.naoProcessados];
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Checkins',
                            detail: 'Erro ao obter checkins do evento',
                            life: 3000,
                        });
                    }
                })
                .finally(() => {
                    this.carregando = false;
                });
        },

        confirmarGerarAtendimentos() {
            this.$confirm.require({
                message: `Tem certeza que deseja solicitar a geração de atendimentos para os checkins selecionados?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.gerarAtendimentos();
                },
            });
        },

        gerarAtendimentos() {
            this.$store.dispatch('addRequest');
            let participantesCheckinsLista = this.checkinsSelecionados.map((item) => {
                return {
                    eventoId: this.evento.eventoId,
                    participanteId: item.participanteId,
                    checkinId: item.checkinId,
                };
            });

            let eventosParticipantesCheckinsLista = {
                eventosParticipantesCheckinsLista: [...participantesCheckinsLista],
            };
            CheckinEventosService.gerarAtandimento(this.evento.eventoId, eventosParticipantesCheckinsLista)
                .then((response) => {
                    if (response?.success) {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Checkins',
                            detail: 'Solicitação para geração dos atendimentos realizada com sucesso',
                            life: 3000,
                        });
                        this.checkinsSelecionados = [];
                        this.obterCheckins();
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Checkins',
                            detail: 'Erro ao solicitar a geração dos atendimentos',
                            life: 3000,
                        });
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
        mostrarChkBox(permiteIntegracao) {
            return this.evento.geraAtendimento && permiteIntegracao;
        },
        toggleCheckins() {
            if (this.checkinsSelecionados && this.checkinsSelecionados.length > 0) {
                this.checkinsSelecionados = [];
            } else {
                this.checkinsSelecionados = [
                    ...this.checkinsProcessados.filter((item) => {
                        return item.permiteIntegracao;
                    }),
                ];
            }
        },
    },

    watch: {
        evento() {
            if (this.evento) {
                this.obterCheckins();
            }
        },
    },
    mounted() {
        if (this.evento) {
            this.obterCheckins();
        }
    },
};
</script>
