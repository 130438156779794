<template>
    <Button label="Gerar QrCode" icon="pi pi-qrcode" class="ml-2" @click="gerarQrCode()" v-if="$temAcessoView('CHECKINS-EVENTOS-10')" />
    <Dialog header="QR Code" v-model:visible="mostrarDialog" :style="{ width: '30vw' }" @hide="reiniciarDados()">
        <div class="flex justify-content-center">
            <img width="300" height="300" :src="`data:image/PNG;base64,${qrCode}`" v-if="qrCode" alt="QrCode" />
        </div>
        <template #footer>
            <Button label="Fechar" icon="pi pi-times-circle" class="p-button-secondary" @click="fecharDialog()" autofocus />
        </template>
    </Dialog>
</template>

<script>
import EventosServices from './services';

export default {
    props: {
        evento: {
            type: Object,
        },
    },

    data() {
        return {
            mostrarDialog: false,
            qrCode: null,
        };
    },

    methods: {
        gerarQrCode() {
            this.$store.dispatch('addRequest');

            EventosServices.gerarQrCode(this.evento.eventoId)
                .then((response) => {
                    if (response?.success) {
                        this.qrCode = response.data;
                        this.abrirDialog();
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        abrirDialog() {
            this.mostrarDialog = true;
        },

        reiniciarDados() {
            this.qrCode = null;
        },

        fecharDialog() {
            this.mostrarDialog = false;
            this.reiniciarDados();
        },
    },
};
</script>
