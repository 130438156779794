<template>
    <Button :label="`Reprocessar Participantes`" icon="pi pi-refresh" @click="confirmar()" class="ml-2" />
</template>

<script>
import ParticipantesServices from './services';

export default {
    props: {
        participantes: {
            type: Array,
        },
    },

    emits: ['atualizar'],

    methods: {
        confirmar() {
            this.$confirm.require({
                message: `Tem certeza que deseja reprocessar os participantes selecionados?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.reprocessarParticipantes();
                },
            });
        },

        reprocessarParticipantes() {
            this.$store.dispatch('addRequest');
            let participantesId = this.participantes.map((p) => p.participanteId);
            ParticipantesServices.reprocessarParticipantes(this.$route.params.id, participantesId)
                .then((response) => {
                    if (response?.success) {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Participantes',
                            detail: 'Participantes atualizados com sucesso',
                            life: 3000,
                        });
                        this.$emit('atualizar');
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Participantes',
                            detail: response.errors[0],
                            life: 3000,
                        });
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
    },
};
</script>
