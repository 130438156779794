export const eventoParticipanteStatus = {
    AGUARDANDOINTEGRACAO: 0,
    INTEGRADO: 1,
    FALHAINTEGRACAO: 2,
    ERROINTEGRACAO: 3,
    NAOAPLICAVEL: 4,
    INTEGRADOPF: 5,
    INTEGRADOPFPJ: 6,
    EMPROCESSAMENTO: 7
};

export const vinculoStatus = {
    AGUARDANDOVINCULACAO: 0,
    VINCULADO: 1,
    FALHANAVINCULACAO: 2,
    NAOAPLICAVEL: 3,
};

export const participanteStatus = {
    AGUARDANDOINTEGRACAO: 0,
    INTEGRADO: 1,
    FALHANAINTEGRACAO: 2,
    ERRONAINTEGRACAO: 3,
    NAOAPLICAVEL: 4,
    EMPROCESSAMENTO: 5
};

export const participanteStatusIntegracaoExterna = {
    INVALIDO: 0,
    VALIDO: 1,
    INVALIDOEMPROCESSAMENTO: 2,
};