<template>
    <font-awesome-icon icon="info-circle" @click="toDetalhar()" size="lg" class="icon-button p-text-primary" title="Detalhar" />
</template>

<script>
export default {
    props: {
        checkin: {
            type: Object,
        },
    },

    methods: {
        toDetalhar() {
            this.$router.push({
                name: 'Checkins_Eventos_Participantes_Checkins_Detalhar',
                params: {
                    id: this.$route.params.id,
                    participanteId: this.checkin.participanteId,
                    checkinId: this.checkin.checkinId,
                },
            });
        },
    },
};
</script>
