<template>
    <Accordion :multiple="true" :activeIndex="[0]">
        <AccordionTab header="Resumo Participantes">
            <ProgressBar mode="indeterminate" style="height: 1em" v-if="carregando" />
            <div class="grid" v-if="!carregando && dashboard">
                <div class="col-12 md:col-6 lg:col-3" v-for="card in cards5" :key="card.titulo">
                    <div class="surface-card shadow-2 p-3 border-round">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <span class="block text-500 font-medium mb-3">{{ card.titulo }}</span>
                                <div class="text-900 font-medium text-xl">{{ dashboard[card.valor] }}</div>
                            </div>
                            <div class="flex align-items-center justify-content-center border-round"
                                :class="[card.corBox]" style="width: 2.5rem; height: 2.5rem">
                                <i class="text-blue-500 text-xl" :class="[card.icon, card.corTexto]"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <tabela :data="participantes">
                <template #conteudo>
                    <Column field="Código" header="Código" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.codigoErro }}
                        </template>
                    </Column>
                    <Column field="Descricao" header="Descricao" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.descricaoErro }}
                        </template>
                    </Column>
                    <Column field="Quantidade" header="Quantidade" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.quantidade }}
                        </template>
                    </Column>
                </template>
            </tabela>
        </AccordionTab>
        <AccordionTab header="Visão Geral">
            <ProgressBar mode="indeterminate" style="height: 1em" v-if="carregando" />
            <div class="grid" v-if="!carregando && dashboard">
                <div class="col-12 md:col-6 lg:col-3" v-for="card in cards1" :key="card.titulo">
                    <div class="surface-card shadow-2 p-3 border-round">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <span class="block text-500 font-medium mb-3">{{ card.titulo }}</span>
                                <div class="text-900 font-medium text-xl">{{ dashboard[card.valor] }}</div>
                            </div>
                            <div class="flex align-items-center justify-content-center border-round"
                                :class="[card.corBox]" style="width: 2.5rem; height: 2.5rem">
                                <i class="text-blue-500 text-xl" :class="[card.icon, card.corTexto]"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid" v-if="!carregando && dashboard">
                <div class="col-12 md:col-6 lg:col-3" v-for="card in cards2" :key="card.titulo">
                    <div class="surface-card shadow-2 p-3 border-round">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <span class="block text-500 font-medium mb-3">{{ card.titulo }}</span>
                                <div class="text-900 font-medium text-xl">{{ dashboard[card.valor] }}</div>
                            </div>
                            <div class="flex align-items-center justify-content-center border-round"
                                :class="[card.corBox]" style="width: 2.5rem; height: 2.5rem">
                                <i class="text-blue-500 text-xl" :class="[card.icon, card.corTexto]"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid" v-if="!carregando && dashboard">
                <div class="col-12 md:col-6 lg:col-3" v-for="card in cards3" :key="card.titulo">
                    <div class="surface-card shadow-2 p-3 border-round">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <span class="block text-500 font-medium mb-3">{{ card.titulo }}</span>
                                <div class="text-900 font-medium text-xl">{{ dashboard[card.valor] }}</div>
                            </div>
                            <div class="flex align-items-center justify-content-center border-round"
                                :class="[card.corBox]" style="width: 2.5rem; height: 2.5rem">
                                <i class="text-blue-500 text-xl" :class="[card.icon, card.corTexto]"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid" v-if="!carregando && dashboard">
                <div class="col-12 md:col-6 lg:col-3" v-for="card in cards4" :key="card.titulo">
                    <div class="surface-card shadow-2 p-3 border-round">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <span class="block text-500 font-medium mb-3">{{ card.titulo }}</span>
                                <div class="text-900 font-medium text-xl">{{ dashboard[card.valor] }}</div>
                            </div>
                            <div class="flex align-items-center justify-content-center border-round"
                                :class="[card.corBox]" style="width: 2.5rem; height: 2.5rem">
                                <i class="text-blue-500 text-xl" :class="[card.icon, card.corTexto]"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <tabela :data="participantes">
                <template #conteudo>
                    <Column field="Código" header="Código" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.codigoErro }}
                        </template>
                    </Column>
                    <Column field="Descricao" header="Descricao" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.descricaoErro }}
                        </template>
                    </Column>
                    <Column field="Quantidade" header="Quantidade" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.quantidade }}
                        </template>
                    </Column>
                </template>
            </tabela>
        </AccordionTab>
    </Accordion>
</template>

<script>
import EventosServices from './services';
import ParticipanteService from '../participantes/services';

export default {
    components: {
    },
    props: {
        evento: {
            type: Object,
        },
    },

    data() {
        return {
            carregando: false,
            participantes: null,
            cards1: [
                { titulo: 'Eventos', icon: 'pi pi-calendar', corBox: 'bg-blue-100', corTexto: 'text-blue-500', valor: 'qtdEventos' },
                { titulo: 'Participantes', icon: 'pi pi-users', corBox: 'bg-cyan-100', corTexto: 'text-cyan-500', valor: 'qtdParticipantesTotal' },
                { titulo: 'Participantes Válidos', icon: 'pi pi-users', corBox: 'bg-green-100', corTexto: 'text-green-500', valor: 'qtdParticipantesValidos' },
                {
                    titulo: 'Participantes Inválidos',
                    icon: 'pi pi-users',
                    corBox: 'bg-pink-100',
                    corTexto: 'text-pink-500',
                    valor: 'qtdParticipantesInvalidos',
                },
            ],
            cards2: [
                {
                    titulo: 'Participantes PF',
                    icon: 'pi pi-users',
                    corBox: 'bg-cyan-100',
                    corTexto: 'text-cyan-500',
                    valor: 'qtdParticipantesPf',
                },
                {
                    titulo: 'Participantes PF/PJ',
                    icon: 'pi pi-users',
                    corBox: 'bg-cyan-100',
                    corTexto: 'text-cyan-500',
                    valor: 'qtdParticipantesPfPj',
                },
            ],
            cards3: [
                {
                    titulo: 'Checkins',
                    icon: 'pi pi-id-card',
                    corBox: 'bg-cyan-100',
                    corTexto: 'text-cyan-500',
                    valor: 'qtdCheckinsTotal',
                },
                {
                    titulo: 'Checkins Processados',
                    icon: 'pi pi-id-card',
                    corBox: 'bg-green-100',
                    corTexto: 'text-green-500',
                    valor: 'qtdCheckinsProcessados',
                },
                {
                    titulo: 'Checkins Em Processamento',
                    icon: 'pi pi-id-card',
                    corBox: 'bg-yellow-100',
                    corTexto: 'text-yellow-500',
                    valor: 'qtdCheckinsEmProcessamento',
                },
                {
                    titulo: 'Checkins Não Processados',
                    icon: 'pi pi-id-card',
                    corBox: 'bg-orange-100',
                    corTexto: 'text-orange-500',
                    valor: 'qtdCheckinsNaoProcessados',
                },
            ],
            cards4: [
                {
                    titulo: 'Participantes PF - Integrados',
                    icon: 'pi pi-users',
                    corBox: 'bg-green-100',
                    corTexto: 'text-green-500',
                    valor: 'qtdParticipantesPf_PfIntegrados',
                },
                {
                    titulo: 'Participantes PF - Aguard. Integração',
                    icon: 'pi pi-users',
                    corBox: 'bg-orange-100',
                    corTexto: 'text-orange-500',
                    valor: 'qtdParticipantesPf_PfAguardandoIntegracao',
                },
                {
                    titulo: 'Participantes PF - Falha na Integração',
                    icon: 'pi pi-users',
                    corBox: 'bg-yellow-100',
                    corTexto: 'text-yellow-500',
                    valor: 'qtdParticipantesPf_PfFalhaNaIntegracao',
                },
                {
                    titulo: 'Participantes PF - Erro na Integração',
                    icon: 'pi pi-users',
                    corBox: 'bg-pink-100',
                    corTexto: 'text-pink-500',
                    valor: 'qtdParticipantesPf_PfErroNaIntegracao',
                },
                {
                    titulo: 'Participantes PF/PJ - PF Integrados',
                    icon: 'pi pi-users',
                    corBox: 'bg-green-100',
                    corTexto: 'text-green-500',
                    valor: 'qtdParticipantesPfPj_PfIntegrados',
                },
                {
                    titulo: 'Participantes PF/PJ - PF Aguard. Integração',
                    icon: 'pi pi-users',
                    corBox: 'bg-orange-100',
                    corTexto: 'text-orange-500',
                    valor: 'qtdParticipantesPfPj_PfAguardandoIntegracao',
                },
                {
                    titulo: 'Participantes PF/PJ - PF Falha na Integração',
                    icon: 'pi pi-users',
                    corBox: 'bg-yellow-100',
                    corTexto: 'text-yellow-500',
                    valor: 'qtdParticipantesPfPj_PfFalhaNaIntegracao',
                },
                {
                    titulo: 'Participantes PF/PJ - PF Erro na Integração',
                    icon: 'pi pi-users',
                    corBox: 'bg-pink-100',
                    corTexto: 'text-pink-500',
                    valor: 'qtdParticipantesPfPj_PfErroNaIntegracao',
                },
                {
                    titulo: 'Participantes PF/PJ - PJ Integrados',
                    icon: 'pi pi-users',
                    corBox: 'bg-green-100',
                    corTexto: 'text-green-500',
                    valor: 'qtdParticipantesPfPj_PjIntegrados',
                },
                {
                    titulo: 'Participantes PF/PJ - PJ Aguard. Integração',
                    icon: 'pi pi-users',
                    corBox: 'bg-orange-100',
                    corTexto: 'text-orange-500',
                    valor: 'qtdParticipantesPfPj_PjAguardandoIntegracao',
                },
                {
                    titulo: 'Participantes PF/PJ - PJ Falha na Integração',
                    icon: 'pi pi-users',
                    corBox: 'bg-yellow-100',
                    corTexto: 'text-yellow-500',
                    valor: 'qtdParticipantesPfPj_PjFalhaNaIntegracao',
                },
                {
                    titulo: 'Participantes PF/PJ - PJ Erro na Integração',
                    icon: 'pi pi-users',
                    corBox: 'bg-pink-100',
                    corTexto: 'text-pink-500',
                    valor: 'qtdParticipantesPfPj_PjErroNaIntegracao',
                },
                {
                    titulo: 'Participantes PF - Evento - Integrados',
                    icon: 'pi pi-calendar-plus',
                    corBox: 'bg-green-100',
                    corTexto: 'text-green-500',
                    valor: 'qtdParticipantesPf_EventoIntegrados',
                },
                {
                    titulo: 'Participantes PF - Evento - Aguard. Integração',
                    icon: 'pi pi-calendar-plus',
                    corBox: 'bg-orange-100',
                    corTexto: 'text-orange-500',
                    valor: 'qtdParticipantesPf_EventoAguardandoIntegracao',
                },
                {
                    titulo: 'Participantes PF - Evento - Falha na Integração',
                    icon: 'pi pi-calendar-plus',
                    corBox: 'bg-yellow-100',
                    corTexto: 'text-yellow-500',
                    valor: 'qtdParticipantesPf_EventoFalhaNaIntegracao',
                },
                {
                    titulo: 'Participantes PF - Evento - Erro na Integração',
                    icon: 'pi pi-calendar-plus',
                    corBox: 'bg-pink-100',
                    corTexto: 'text-pink-500',
                    valor: 'qtdParticipantesPf_EventoErroNaIntegracao',
                },
                {
                    titulo: 'Participantes PF/PJ - Evento - Integrados',
                    icon: 'pi pi-calendar-plus',
                    corBox: 'bg-green-100',
                    corTexto: 'text-green-500',
                    valor: 'qtdParticipantesPfPj_EventoIntegrados',
                },
                {
                    titulo: 'Participantes PF/PJ - Evento - Aguard. Integração',
                    icon: 'pi pi-calendar-plus',
                    corBox: 'bg-orange-100',
                    corTexto: 'text-orange-500',
                    valor: 'qtdParticipantesPfPj_EventoAguardandoIntegracao',
                },
                {
                    titulo: 'Participantes PF/PJ - Evento - Falha na Integração',
                    icon: 'pi pi-calendar-plus',
                    corBox: 'bg-yellow-100',
                    corTexto: 'text-yellow-500',
                    valor: 'qtdParticipantesPfPj_EventoFalhaNaIntegracao',
                },
                {
                    titulo: 'Participantes PF/PJ - Evento - Erro na Integração',
                    icon: 'pi pi-calendar-plus',
                    corBox: 'bg-pink-100',
                    corTexto: 'text-pink-500',
                    valor: 'qtdParticipantesPfPj_EventoErroNaIntegracao',
                },
            ],
            cards5: [
                { titulo: ' Total de Participantes', icon: 'pi pi-users', corBox: 'bg-cyan-100', corTexto: 'text-cyan-500', valor: 'qtdParticipantesTotal' },
                { titulo: 'Participantes Bipados Ok', icon: 'pi pi-users', corBox: 'bg-green-100', corTexto: 'text-green-500', valor: 'qtdParticipantesBipadosOk' },
                { titulo: 'Participantes Bipados Não OK', icon: 'pi pi-users', corBox: 'bg-pink-100', corTexto: 'text-pink-500', valor: 'qtdParticipantesBipadosNaoOk' },
                { titulo: 'Participantes Não Bipados', icon: 'pi pi-users', corBox: 'bg-yellow-100', corTexto: 'text-yellow-500', valor: 'qtdNaoBipados' },
            ],
            dashboard: null,
        };
    },

    methods: {
        obterDashboard() {
            this.carregando = true;
            EventosServices.obterDashboard(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.dashboard = response.data;
                } else {
                    this.dashboard = null;
                }
                this.carregando = false;
            });
        },
        obterTabelaErrosParticipante() {
            ParticipanteService.obterTabelaErrosParticipante(this.$route.params.id).then((response) => {
                if (response?.success) {
                    console.log(response)
                    this.participantes = response.data;
                } else {
                    this.participantes = null;
                }
            })
        }
    },

    mounted() {
        this.obterDashboard();
        this.obterTabelaErrosParticipante();
    },

    watch: {
        evento() {
            if (this.evento) {
                this.obterDashboard();
                this.obterTabelaErrosParticipante();
            }
        },
    },
};
</script>