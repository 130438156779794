<template>
    <Button label="Novo Evento" icon="pi pi-plus" @click="toInserir()" v-if="$temAcessoView('CHECKINS-EVENTOS-01')" />
</template>
<script>
export default {
    props: {
        nivel: {
            type: Number,
        },

        evento: {
            type: Object,
        },
    },

    methods: {
        toInserir() {
            this.$router.push({
                name: 'Checkins_Eventos_Inserir',
                query: {
                    nivel: this.nivel,
                    id: this.nivel == 0 ? '' : this.evento.eventoId,
                    pbcnpj: this.evento?.buscarCnpj,
                },
            });
        },
    },
};
</script>
