<template>
    <detalhe titulo="Projeto" :size="sizeLabel">{{ atendimentoDetalhe?.projeto }}</detalhe>
    <detalhe titulo="Ação" :size="sizeLabel">{{ atendimentoDetalhe?.acao }}</detalhe>
    <detalhe titulo="Instrumento" :size="sizeLabel">{{ atendimentoDetalhe?.instrumento }}</detalhe>
    <detalhe titulo="Tipo da Realização" :size="sizeLabel">{{ atendimentoDetalhe?.tipoRealizacao }}</detalhe>
    <detalhe titulo="Nome da Realização" :size="sizeLabel">{{ atendimentoDetalhe?.nomeRealizacao }}</detalhe>
    <detalhe titulo="Carga Horária" :size="sizeLabel"><span v-if="atendimentoDetalhe?.cargaHoraria">{{
        atendimentoDetalhe?.cargaHoraria }} minutos</span></detalhe>
    <detalhe titulo="Tema" :size="sizeLabel">{{ atendimentoDetalhe?.tema }}</detalhe>
    <detalhe titulo="Canal Atendimento" :size="sizeLabel">{{ atendimentoDetalhe?.canalAtendimento }}</detalhe>
    <detalhe titulo="Unidade Organizacional" :size="sizeLabel"><span
            v-if="atendimentoDetalhe?.codUnidadeOrganizacional">{{ atendimentoDetalhe?.codUnidadeOrganizacional }} - {{
                atendimentoDetalhe?.unidadeOrganizacional }}</span></detalhe>
    <detalhe titulo="Responsável" :size="sizeLabel"><span v-if="atendimentoDetalhe?.cpfResponsavel">{{
        $toCpf(atendimentoDetalhe?.cpfResponsavel) }} - {{ atendimentoDetalhe?.responsavel }}</span></detalhe>
    <detalhe titulo="Orientação ao Cliente" :size="sizeLabel"><span v-if="atendimentoDetalhe?.orientacaoCliente">{{
        atendimentoDetalhe?.orientacaoCliente }}</span></detalhe>
</template>

<script>
import AtendimentoDetalheServices from './services';

export default {
    props: {
        evento: {
            type: Object,
        },
        buttonAtendimento: {
            type: Boolean,
        }
    },

    data() {
        return {
            atendimentoDetalhe: null,
            sizeLabel: '180',
        };
    },

    methods: {
        obterAtendimentoDetalhe() {
            this.$store.dispatch('addRequest');
            AtendimentoDetalheServices.obterAtendimentoDetalhePorEventoId(this.evento.eventoId).then((response) => {
                if (response?.success) {
                    this.atendimentoDetalhe = response.data;
                } else {
                    this.atendimentoDetalhe = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        ObterOrientacaoEventoPaiPraFilho() {
            this.$store.dispatch('addRequest');
            AtendimentoDetalheServices.obterOrientacaoEventoPaiPraFilho(this.$route.params.id)
                .then((response) => {
                    if (response?.success) {
                        this.atendimentoDetalhe = response.data;
                        this.processarView();
                    } else {
                        this.atendimentoDetalhe = null;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                    this.obterAtendimentoDetalhe();
                });
        }

    },

    watch: {
        evento() {
            if (this.evento) {
                this.obterAtendimentoDetalhe();
            }
        },
        buttonAtendimento() {
            if (this.buttonAtendimento) {
                this.ObterOrientacaoEventoPaiPraFilho();
            }
        }
    },

    mounted() {
        if (this.evento) {
            this.obterAtendimentoDetalhe();
        }
    },
};
</script>
