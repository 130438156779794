<template>
    <ProgressBar mode="indeterminate" style="height: 1em" v-if="carregando" />
    <div class="flex justify-content-end">
                <p>Legenda:</p>
                <p><font-awesome-icon icon="check-circle" size="lg" class="icon-sucesso ml-3" /> Sucesso</p>
                <p><font-awesome-icon icon="minus-circle" size="lg" class="icon-aplicavel ml-3" /> Não aplicável</p>
                <p><font-awesome-icon icon="exclamation-circle" size="lg" class="icon-falha ml-3" /> Falha na integração</p>
                <p><font-awesome-icon icon="times-circle" size="lg" class="icon-erro ml-3" /> Erro na integração</p>
                <p><font-awesome-icon icon="hourglass-half" size="lg" class="icon-aguardando ml-3" /> Aguardando integração</p>
            </div> 
    <tabela
        :data="participantes"
        :globalFilterFields="['codigoExterno', 'nome', 'cpf', 'email', 'telefone']"
        dataKey="participanteId"
        stateKey="dt-checkins-participantes"
        :filtersProp="filtros"
        :filterCallBack="dadosFiltrados"
        v-show="!carregando"
    >
        <template #botoes>
            <btn-reprocessar-participantes
                :participantes="participantesSelecionadosInvalidos"
                @atualizar="obterParticipantesAtualizados()"
                v-if="mostrarBtnReprocessarParticipantes"
            >
            </btn-reprocessar-participantes>
            <btn-reiniciar-integracao-em-lote
                :participantes="participantesSelecionadosPf"
                tipo="PF"
                @atualizar="obterParticipantesAtualizados()"
                v-if="mostrarBtnReiniciarIntegracaoPf"
            ></btn-reiniciar-integracao-em-lote>
            <btn-reiniciar-integracao-em-lote
                :participantes="participantesSelecionadosPj"
                tipo="PJ"
                @atualizar="obterParticipantesAtualizados()"
                v-if="mostrarBtnReiniciarIntegracaoPj"
            ></btn-reiniciar-integracao-em-lote>
            <btn-reiniciar-integracao-evento-em-lote
                :participantes="participantesSelecionadosEvento"
                @atualizar="obterParticipantesAtualizados()"
                v-if="mostrarBtnReiniciarIntegracaoEvento"
            ></btn-reiniciar-integracao-evento-em-lote>
            <btn-reiniciar-integracao-evento-como-pf-em-lote
                :participantes="participantesSelecionadosEventoComoPf"
                @atualizar="obterParticipantesAtualizados()"
                v-if="mostrarBtnReiniciarIntegracaoEventoComoPf"
            ></btn-reiniciar-integracao-evento-como-pf-em-lote>
            <Button type="button" icon="pi pi-filter-slash" label="Limpar Filtros" class="p-button-outlined ml-4" @click="clearFilter()" />
        </template>
        <template #conteudo>
            <Column headerStyle="width: 7em">
                <template #header>
                    <font-awesome-icon icon="check-square" @click="toggleParticipantes()" size="lg" class="icon-button" title="Marcar/Desmarcar Todos" />
                </template>
                <template #body="slotProps">
                    <div class="white-space-nowrap">
                        <Checkbox
                            name="participantesSelecionados"
                            :value="slotProps.data"
                            v-model="participantesSelecionados"
                            v-if="mostrarChkBox(slotProps.data)"
                            class="ml-1 mr-3"
                        />
                        <btn-detalhar :participante="slotProps.data"></btn-detalhar>
                    </div>
                </template>
            </Column>
            <Column field="codigoExterno" header="Código" :sortable="true">
                <template #body="slotProps">
                    <span>{{ slotProps.data.codigoExterno }}</span>
                </template>
            </Column>
            <Column field="nome" header="Nome" :sortable="true">
                <template #body="slotProps">
                    <span>{{ slotProps.data.nome }}</span>
                </template>
            </Column>
            <Column field="statusIntegracaoExternaDescr" header="Situação" :sortable="true" filterField="statusIntegracaoExterna" :showFilterMatchModes="false">
                <template #body="slotProps">
                    <div class="icon-text-contaniner">
                        <font-awesome-icon
                            :icon="iconStatus(slotProps.data.statusIntegracaoExternaDescr)"
                            size="lg"
                            :class="iconClass(slotProps.data.statusIntegracaoExternaDescr)"
                        />
                    </div>
                </template>
                <template #filter="{ filterModel }">
                    <MultiSelect
                        v-model="filterModel.value"
                        :options="statusIntegracaoExternaLista"
                        optionLabel="descricao"
                        optionValue="codigo"
                        placeholder="Qualquer"
                        class="p-column-filter"
                    >
                    </MultiSelect>
                </template>
            </Column>
            <Column
                field="statusIntegracaoInternaPfDescr"
                header="Status PF"
                :sortable="true"
                filterField="statusIntegracaoInternaPf"
                :showFilterMatchModes="false"
            >
                <template #body="slotProps">
                    <div class="icon-text-contaniner">
                        <font-awesome-icon
                            :icon="iconStatus(slotProps.data.statusIntegracaoInternaPfDescr)"
                            size="lg"
                            :class="iconClass(slotProps.data.statusIntegracaoInternaPfDescr)"
                        />
                    </div>
                </template>
                <template #filter="{ filterModel }">
                    <MultiSelect
                        v-model="filterModel.value"
                        :options="statusIntegracaoInternaPfLista"
                        optionLabel="descricao"
                        optionValue="codigo"
                        placeholder="Qualquer"
                        class="p-column-filter"
                    >
                    </MultiSelect>
                </template>
            </Column>
            <Column
                field="statusIntegracaoInternaPjDescr"
                header="Status PJ"
                :sortable="true"
                filterField="statusIntegracaoInternaPj"
                :showFilterMatchModes="false"
            >
                <template #body="slotProps">
                    <div class="icon-text-contaniner">
                        <font-awesome-icon
                            :icon="iconStatus(slotProps.data.statusIntegracaoInternaPjDescr)"
                            size="lg"
                            :class="iconClass(slotProps.data.statusIntegracaoInternaPjDescr)"
                        />
                    </div>
                </template>
                <template #filter="{ filterModel }">
                    <MultiSelect
                        v-model="filterModel.value"
                        :options="statusIntegracaoInternaPjLista"
                        optionLabel="descricao"
                        optionValue="codigo"
                        placeholder="Qualquer"
                        class="p-column-filter"
                    >
                    </MultiSelect>
                </template>
            </Column>
            <Column field="statusVinculoPjPfDescr" header="Status Vínculo" :sortable="true" filterField="statusVinculoPjPf" :showFilterMatchModes="false">
                <template #body="slotProps">
                    <div class="icon-text-contaniner">
                        <font-awesome-icon
                            :icon="iconStatus(slotProps.data.statusVinculoPjPfDescr)"
                            size="lg"
                            :class="iconClass(slotProps.data.statusVinculoPjPfDescr)"
                        />
                    </div>
                </template>
                <template #filter="{ filterModel }">
                    <MultiSelect
                        v-model="filterModel.value"
                        :options="statusVinculoPjPfLista"
                        optionLabel="descricao"
                        optionValue="codigo"
                        placeholder="Qualquer"
                        class="p-column-filter"
                    >
                    </MultiSelect>
                </template>
            </Column>
            <Column
                field="statusIntegracaoInternaEventoDescr"
                header="Status Evento"
                :sortable="true"
                filterField="statusIntegracaoInternaEvento"
                :showFilterMatchModes="false"
            >
                <template #body="slotProps">
                    <div class="icon-text-container">
                        <font-awesome-icon
                            :icon="iconStatus(slotProps.data.statusIntegracaoInternaEventoDescr)"
                            size="lg"
                            :class="iconClass(slotProps.data.statusIntegracaoInternaEventoDescr)"
                        />
                        <span v-if="slotProps.data.integrarApenasPf" class="ml-2" title="Integração PF forçada">
                            <font-awesome-icon
                                :icon="iconStatus(slotProps.data.integrarApenasPf)"
                                size="lg"
                                :class="iconClass(slotProps.data.integrarApenasPf)"
                            />
                        </span>
                    </div>
                </template>
                <template #filter="{ filterModel }">
                    <MultiSelect
                        v-model="filterModel.value"
                        :options="statusIntegracaoInternaEventoLista"
                        optionLabel="descricao"
                        optionValue="codigo"
                        placeholder="Qualquer"
                        class="p-column-filter"
                    >
                    </MultiSelect>
                </template>
            </Column>
            <Column field="codigoErro" header="Código do Erro" :sortable="true">
                <template #body="slotProps">
                    <span
                        class="cursor-pointer"
                        v-if="slotProps.data.codigoErro"
                        :title="slotProps.data.descricaoStatus"
                        v-bind:class="{ 'codigo-erro': slotProps.data.codigoErro }"
                    >
                        {{ 'Erro: ' + slotProps.data.codigoErro }}
                    </span>
                </template>
                <template #filter="{ filterModel }">
                    <MultiSelect
                        v-model="filterModel.value"
                        :options="codigoErroLista"
                        optionLabel="descricao"
                        optionValue="codigo"
                        placeholder="Qualquer"
                        class="p-column-filter"
                    >
                    </MultiSelect>
                </template>
            </Column>
        </template>
    </tabela>
</template>

<style scoped></style>
<script>
import ParticipantesServices from './services';
import BtnDetalhar from './BtnDetalhar.vue';
import { FilterMatchMode } from 'primevue/api';
import { eventoParticipanteStatus, participanteStatus, participanteStatusIntegracaoExterna, vinculoStatus } from './ParticipanteStatus';
import BtnReiniciarIntegracaoEmLote from './BtnReiniciarIntegracaoEmLote.vue';
import BtnReiniciarIntegracaoEventoEmLote from './BtnReiniciarIntegracaoEventoEmLote.vue';
import BtnReiniciarIntegracaoEventoComoPfEmLote from './BtnReiniciarIntegracaoEventoComoPfEmLote.vue';
import BtnReprocessarParticipantes from './BtnReprocessarParticipantes.vue';

export default {
    components: {
        BtnDetalhar,
        BtnReiniciarIntegracaoEmLote,
        BtnReiniciarIntegracaoEventoEmLote,
        BtnReiniciarIntegracaoEventoComoPfEmLote,
        BtnReprocessarParticipantes,
    },

    props: {
        evento: {
            type: Object,
        },
    },

    data() {
        return {
            participantes: null,
            carregando: false,
            filtros: null,
            participantesFiltrados: [],
            participantesSelecionados: [],
        };
    },

    methods: {
        iconStatus(status) {
            let icon;
            switch (status) {
                case 'INTEGRADO':
                case 'INTEGRADO PF':
                case 'VÁLIDO':
                case 'VINCULADO':
                case 'INTEGRADO PF/PJ':
                    icon = 'check-circle';
                    break;
                case 'FALHA INTEGRACAO':
                case 'FALHA NA VINCULAÇÃO':
                case 'FALHA NA INTEGRAÇÃO':
                case true:
                    icon = 'exclamation-circle';
                    break;
                case 'ERRO NA INTEGRAÇÃO':
                case 'INVÁLIDO':
                case 'INVÁLIDO EM PROCESSAMENTO':
                    icon = 'times-circle';
                    break;
                case 'EM PROCESSAMENTO': 
                case 'AGUARDANDO INTEGRAÇÃO':
                    icon = 'hourglass-half';
                    break;
                case 'NÃO APLICÁVEL':
                    icon = 'minus-circle';
                    break;
            }
            return icon;
        },
        iconClass(status) {
            switch (status) {
                case 'INTEGRADO':
                case 'INTEGRADO PF':
                case 'VÁLIDO':
                case 'VINCULADO':
                case 'INTEGRADO PF/PJ':
                    return 'icon-sucesso';
                case 'FALHA NA VINCULAÇÃO':
                case 'FALHA NA INTEGRAÇÃO':
                    return 'icon-falha';
                case 'ERRO NA INTEGRAÇÃO':
                case 'INVÁLIDO':
                case 'INVÁLIDO EM PROCESSAMENTO':
                case true:
                    return 'icon-erro';
                case 'NÃO APLICÁVEL':
                    return 'icon-aplicavel';

                case 'AGUARDANDO INTEGRAÇÃO':
                case 'EM PROCESSAMENTO': 
                return 'icon-aguardando';
            }
        },

        obterParticipantes() {
            this.carregando = true;
            ParticipantesServices.obterParticipantesEvento(this.evento.eventoId).then((response) => {
                if (response?.success) {
                    this.participantes = response.data;
                    this.iniciarFiltros();
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Participantes',
                        detail: 'Erro ao obter participantes do evento',
                        life: 3000,
                    });
                }
                this.carregando = false;
            });
        },

        clearFilter() {
            this.filtros = null;
            this.iniciarFiltros();
        },

        
        iniciarFiltros() {
            this.filtros = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                statusIntegracaoExterna: { value: null, matchMode: FilterMatchMode.IN },
                statusIntegracaoInternaPf: { value: null, matchMode: FilterMatchMode.IN },
                codStatusIntegracaoPf: { value: null, matchMode: FilterMatchMode.IN },
                statusIntegracaoInternaPj: { value: null, matchMode: FilterMatchMode.IN },
                codStatusIntegracaoPj: { value: null, matchMode: FilterMatchMode.IN },
                statusVinculoPjPf: { value: null, matchMode: FilterMatchMode.IN },
                codStatusVinculoPjPf: { value: null, matchMode: FilterMatchMode.IN },
                statusIntegracaoInternaEvento: { value: null, matchMode: FilterMatchMode.IN },
                codStatusIntegracaoEvento: { value: null, matchMode: FilterMatchMode.IN },
                codigoErro: { value: null, matchMode: FilterMatchMode.IN },
            };
        },

        dadosFiltrados(filterData) {
            this.participantesFiltrados = [...filterData.filteredValue];
        },

        mostrarChkBox(participante) {
            return (
                ((participante.statusIntegracaoInternaPf == participanteStatus.ERRONAINTEGRACAO ||
                    participante.statusIntegracaoInternaPj == participanteStatus.ERRONAINTEGRACAO ||
                    this.verificarParticipanteProcessarComopf(participante)) &&
                    this.$temAcessoView('CHECKINS-EVENTOS-08')) ||
                (participante.statusIntegracaoExterna == participanteStatusIntegracaoExterna.INVALIDO &&
                    this.$temAcessoView('CHECKINS-EVENTOS-14') &&
                    !this.evento?.participantesInternos)
            );
        },

        obterParticipantesAtualizados() {
            this.participantesSelecionados = [];
            this.obterParticipantes();
        },

        toggleParticipantes() {
            if (this.participantesSelecionados && this.participantesSelecionados.length > 0) {
                this.participantesSelecionados = [];
            } else {
                this.participantesSelecionados = [
                    ...this.participantesFiltrados.filter((participante) => {
                        return (
                            participante.statusIntegracaoInternaPf == participanteStatus.ERRONAINTEGRACAO ||
                            participante.statusIntegracaoInternaPj == participanteStatus.ERRONAINTEGRACAO ||
                            this.verificarParticipanteProcessarComopf(participante) ||
                            (participante.statusIntegracaoExterna == participanteStatusIntegracaoExterna.INVALIDO && !this.evento?.participantesInternos)
                        );
                    }),
                ];
            }
        },

        verificarParticipanteProcessarComopf(participante) {
            return (
                participante.statusIntegracaoInternaEvento == eventoParticipanteStatus.ERROINTEGRACAO ||
                participante.statusIntegracaoInternaEvento == eventoParticipanteStatus.FALHAINTEGRACAO ||
                (participante.statusIntegracaoInternaEvento == eventoParticipanteStatus.AGUARDANDOINTEGRACAO &&
                    (participante.statusIntegracaoInternaPj == participanteStatus.FALHANAINTEGRACAO ||
                        participante.statusIntegracaoInternaPj == participanteStatus.ERRONAINTEGRACAO ||
                        participante.statusIntegracaoInternaPj == participanteStatus.AGUARDANDOINTEGRACAO ||
                        participante.statusVinculoPjPf == vinculoStatus.AGUARDANDOVINCULACAO ||
                        participante.statusVinculoPjPf == vinculoStatus.FALHANAVINCULACAO)) ||
                (participante.statusIntegracaoInternaEvento == eventoParticipanteStatus.NAOAPLICAVEL &&
                    participante.statusIntegracaoInternaPf == participanteStatus.INTEGRADO &&
                    (participante.statusIntegracaoInternaPj == participanteStatus.FALHANAINTEGRACAO ||
                        participante.statusIntegracaoInternaPj == participanteStatus.ERRONAINTEGRACAO ||
                        participante.statusIntegracaoInternaPj == participanteStatus.AGUARDANDOINTEGRACAO))
            );
        },
    },

    watch: {
        evento() {
            if (this.evento) {
                this.obterParticipantes();
            }
        },

        $route(to) {
            if (to.name === 'Checkins_Eventos_Detalhar') {
                if (this.$store.getters.atualizar) {
                    this.obterParticipantes();
                    this.$store.dispatch('setAtualizar', false);
                }
            }
        },
    },

    mounted() {
        if (this.evento) {
            this.obterParticipantes();
        }
    },

    computed: {
        codigoErroLista() {
            const result = [];
            const map = new Map();

            this.participantes.forEach((participante) => {
                if (!map.has(participante.codigoErro)) {
                    map.set(participante.codigoErro, true);
                    result.push({
                        codigo: participante.codigoErro,
                        descricao: participante.codigoErro,
                    });
                }
            });

            return result;
        },
        statusIntegracaoExternaLista() {
            const result = [];
            const map = new Map();
            for (const item of this.participantes) {
                if (!map.has(item.statusIntegracaoExterna)) {
                    map.set(item.statusIntegracaoExterna, true); // set any value to Map
                    result.push({
                        codigo: item.statusIntegracaoExterna,
                        descricao: item.statusIntegracaoExternaDescr,
                    });
                }
            }
            return result;
        },

        statusIntegracaoInternaPfLista() {
            const result = [];
            const map = new Map();
            for (const item of this.participantes) {
                if (!map.has(item.statusIntegracaoInternaPf)) {
                    map.set(item.statusIntegracaoInternaPf, true); // set any value to Map
                    result.push({
                        codigo: item.statusIntegracaoInternaPf,
                        descricao: item.statusIntegracaoInternaPfDescr,
                    });
                }
            }
            return result;
        },

        codStatusIntegracaoPfLista() {
            const result = [];
            const map = new Map();
            for (const item of this.participantes) {
                if (!map.has(item.codStatusIntegracaoPf)) {
                    map.set(item.codStatusIntegracaoPf, true); // set any value to Map
                    result.push({
                        codigo: item.codStatusIntegracaoPf,
                        descricao: item.codStatusIntegracaoPf,
                    });
                }
            }
            return result;
        },

        statusIntegracaoInternaPjLista() {
            const result = [];
            const map = new Map();
            for (const item of this.participantes) {
                if (!map.has(item.statusIntegracaoInternaPj)) {
                    map.set(item.statusIntegracaoInternaPj, true); // set any value to Map
                    result.push({
                        codigo: item.statusIntegracaoInternaPj,
                        descricao: item.statusIntegracaoInternaPjDescr,
                    });
                }
            }
            return result;
        },

        codStatusIntegracaoPjLista() {
            const result = [];
            const map = new Map();
            for (const item of this.participantes) {
                if (!map.has(item.codStatusIntegracaoPj)) {
                    map.set(item.codStatusIntegracaoPj, true); // set any value to Map
                    result.push({
                        codigo: item.codStatusIntegracaoPj,
                        descricao: item.codStatusIntegracaoPj,
                    });
                }
            }
            return result;
        },

        statusVinculoPjPfLista() {
            const result = [];
            const map = new Map();
            for (const item of this.participantes) {
                if (!map.has(item.statusVinculoPjPf)) {
                    map.set(item.statusVinculoPjPf, true); // set any value to Map
                    result.push({
                        codigo: item.statusVinculoPjPf,
                        descricao: item.statusVinculoPjPfDescr,
                    });
                }
            }
            return result;
        },

        codStatusVinculoPjPfLista() {
            const result = [];
            const map = new Map();
            for (const item of this.participantes) {
                if (!map.has(item.codStatusVinculoPjPf)) {
                    map.set(item.codStatusVinculoPjPf, true); // set any value to Map
                    result.push({
                        codigo: item.codStatusVinculoPjPf,
                        descricao: item.codStatusVinculoPjPf,
                    });
                }
            }
            return result;
        },

        statusIntegracaoInternaEventoLista() {
            const result = [];
            const map = new Map();
            for (const item of this.participantes) {
                if (!map.has(item.statusIntegracaoInternaEvento)) {
                    map.set(item.statusIntegracaoInternaEvento, true); // set any value to Map
                    result.push({
                        codigo: item.statusIntegracaoInternaEvento,
                        descricao: item.statusIntegracaoInternaEventoDescr,
                    });
                }
            }
            return result;
        },

        codStatusIntegracaoEventoLista() {
            const result = [];
            const map = new Map();
            for (const item of this.participantes) {
                if (!map.has(item.codStatusIntegracaoEvento)) {
                    map.set(item.codStatusIntegracaoEvento, true); // set any value to Map
                    result.push({
                        codigo: item.codStatusIntegracaoEvento,
                        descricao: item.codStatusIntegracaoEvento,
                    });
                }
            }
            return result;
        },

        mostrarBtnReiniciarIntegracaoPf() {
            return this.participantesSelecionados?.some((participante) => participante.statusIntegracaoInternaPf == participanteStatus.ERRONAINTEGRACAO);
        },

        mostrarBtnReiniciarIntegracaoPj() {
            return this.participantesSelecionados?.some((participante) => participante.statusIntegracaoInternaPj == participanteStatus.ERRONAINTEGRACAO);
        },

        mostrarBtnReiniciarIntegracaoEvento() {
            return this.participantesSelecionados?.some(
                (participante) => participante.statusIntegracaoInternaEvento == eventoParticipanteStatus.ERROINTEGRACAO
            );
        },

        mostrarBtnReiniciarIntegracaoEventoComoPf() {
            return this.participantesSelecionados?.some((participante) => this.verificarParticipanteProcessarComopf(participante));
        },

        mostrarBtnReprocessarParticipantes() {
            return (
                this.participantesSelecionados?.some((participante) => participante.statusIntegracaoExterna == participanteStatusIntegracaoExterna.INVALIDO) &&
                !this.evento?.participantesInternos
            );
        },

        participantesSelecionadosPf() {
            return this.participantesSelecionados?.filter((participante) => participante.statusIntegracaoInternaPf == participanteStatus.ERRONAINTEGRACAO);
        },

        participantesSelecionadosPj() {
            return this.participantesSelecionados?.filter((participante) => participante.statusIntegracaoInternaPj == participanteStatus.ERRONAINTEGRACAO);
        },

        participantesSelecionadosEvento() {
            return this.participantesSelecionados?.filter(
                (participante) => participante.statusIntegracaoInternaEvento == eventoParticipanteStatus.ERROINTEGRACAO
            );
        },

        participantesSelecionadosEventoComoPf() {
            return this.participantesSelecionados?.filter((participante) => this.verificarParticipanteProcessarComopf(participante));
        },

        participantesSelecionadosInvalidos() {
            return this.participantesSelecionados?.filter(
                (participante) => participante.statusIntegracaoExterna == participanteStatusIntegracaoExterna.INVALIDO
            );
        },
    },
};
</script>



