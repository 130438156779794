<template>
    <tabela :data="agendas" :globalFilterFields="['complemento', 'nome', 'dataHoraInicio', 'dataHoraFim']">
        <template #conteudo>
            <Column field="complemento" header="Complemento" :sortable="true">
                <template #body="slotProps">
                    {{ slotProps.data.complemento }}
                </template>
            </Column>
            <Column field="nome" header="Nome" :sortable="true">
                <template #body="slotProps">
                    {{ slotProps.data.nome }}
                </template>
            </Column>
            <Column field="dataHoraInicio" header="Data início" :sortable="true">
                <template #body="slotProps">
                    {{ $dateFormat(slotProps.data.dataHoraInicio, "DD/MM/yyyy") }}
                </template>
            </Column>
            <Column field="dataHoraFim" header="Data fim" :sortable="true">
                <template #body="slotProps">
                    {{ $dateFormat(slotProps.data.dataHoraFim, "DD/MM/yyyy") }}
                </template>
            </Column>
        </template>
    </tabela>
</template>
<script>
// import EventosServices from './services';

export default {
    data() {
        return {
            agendas: null,
        };
    },

    props: {
        evento: {
            type: Object,
        },
    },

    methods: {
        // obterListaAgenda() {
        //     this.$store.dispatch('addRequest');
        //     EventosServices.obterListaAgenda(this.evento.codigoInterno).then((response) => {
        //         if (response?.success) {
        //             this.agendas = response.data;
        //         } else {
        //             this.$toast.add({
        //                 severity: 'error',
        //                 summary: 'Agendas',
        //                 detail: 'Erro ao obter agendas do evento',
        //                 life: 3000,
        //             });
        //         }
        //         this.$store.dispatch('removeRequest');
        //     });
        // },
    },

    watch: {
        // evento() {
        //     if (this.evento) {
        //         this.obterListaAgenda();
        //     }
        // },
    },

    mounted() {
        // if (this.evento) {
        //     this.obterListaAgenda();
        // }
    },
}

</script>
