<template>
    <listagem-base :eventos="eventos" :nivel="nivel" :evento="evento"></listagem-base>
</template>


<script>
import EventosServices from './services';
import ListagemBase from './ListagemBase';

export default {
    components: {
        ListagemBase,
    },

    props: {
        evento: {
            type: Object,
        },
    },

    data() {
        return {
            eventos: null,
            nivel: 1,
        };
    },

    methods: {
        obterEventosEvento() {
            this.$store.dispatch('addRequest');
            EventosServices.obterEventosEvento(this.evento.eventoId).then((response) => {
                if (response?.success) {
                    this.eventos = response.data;
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Eventos',
                        detail: 'Erro ao obter eventos do evento',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    watch: {
        evento() {
            if (this.evento) {
                this.obterEventosEvento();
            }
        },
    },

    mounted() {
        if (this.evento) {
            this.obterEventosEvento();
        }
    },
};
</script>
